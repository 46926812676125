import styled from "styled-components";
import { breakpoint, colorsUtils } from "@d2c-ui-components-react";

const FwdDocumentIcon = styled.div<{ bgColor: string; textColor: string }>`
  display: flex;
  align-items: center;
  width: 28px;
  height: 28px;
  padding: 2px 1px;
  margin-right: 8px;
  flex-shrink: 0;
  border-radius: 5px;

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${(props) =>
    props.bgColor === colorsUtils.fwdOrange?.toUpperCase()
      ? props.textColor
      : colorsUtils.fwdOrange};
    }
  }

  &:hover {
    background-color: ${(props) =>
    props.bgColor === colorsUtils.fwdOrange?.toUpperCase()
      ? colorsUtils.fwdWhite20
      : colorsUtils.orange};
  }
`;

const FwdShowAllIcon = styled.div<{ bgColor: string; textColor: string }>`
  display: flex;
  align-items: center;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  font-size: 18px;
  margin-right: 8px;

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${(props) =>
    props.bgColor === colorsUtils.fwdOrange.toUpperCase()
      ? props.textColor
      : colorsUtils.fwdOrange};
    }
  }

  &:hover {
    background-color: ${(props) =>
    props.bgColor === colorsUtils.fwdOrange.toUpperCase()
      ? colorsUtils.fwdWhite20
      : colorsUtils.orange};
  }
`;

const InnerWrap = styled.div<{ useParentPadding?: boolean }>`
  width: 100%;
  padding: ${({ useParentPadding }) =>
    useParentPadding ? "32px 0" : "96px 0"};

  ${breakpoint("md")`
    padding: ${({ useParentPadding }) =>
      useParentPadding ? "32px 0" : "96px 32px"}
  `}

  margin-left: auto;

  margin-right: auto;
`;

const ItemWrapper = styled.section`
  padding: 16px;
`;

const ItemInner = styled.div<{ color: string }>`
  display: flex;
  height: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.color || "#dbdfe1"};
`;

const ItemLink = styled.a`
  display: flex;
  width: 100%;
  align-items: baseline;
  text-decoration: none;
  margin-top: 16px;
  svg path {
  }
`;

const ItemTitle = styled.span<{ color: string }>`
  padding-right: 10px;
  margin-right: auto;
  font-weight: 500;
  line-height: 24px;
  color: ${(props) => props.color};
`;

const Description = styled.div`
  margin: 24px 0;

  a {
    text-decoration: underline;
  }
`;

const ItemDescription = styled.div`
  
`;

const ItemContent = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FwdFileIcon = styled.div<{ bgColor: string; textColor: string }>`
  height: 40px;
  min-width: 40px;
  display: flex;
  position: relative;
  svg {
    width: 40px;
    height: 40px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
`;

const DocumentItemTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
`;

const DocumentItemTop = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
  align-items: center;
  ${FwdDocumentIcon} {
    margin-right: 16px;
  }
`;

const ItemsWrapper = styled.div<{ itemsPerLine: number, designSettings: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-bottom: 0;

  ${(props) => `
    margin-left: ${props.designSettings === "document" ? "0" : "-16px"};
    margin-right: ${props.designSettings === "document" ? "0" : "-16px"};
  `}
 
  ${breakpoint("md")`
    margin-top: 24px;
    margin-bottom: 24px;
    &.main-doc {
      margin-bottom: 12px;
    }
  `}

  ${ItemWrapper} {
    flex-grow: 1;
    max-width: 100%;
    flex-basis: 100%;
    
    ${(props) => breakpoint("md")`
      flex-grow: 0;
      max-width: calc(100% / ${props.itemsPerLine});
      flex-basis: calc(100% / ${props.itemsPerLine});
    `}

    &.document {
      padding: 24px;
      box-shadow: 0px 0px 8px 0px rgba(24, 48, 40, 0.20);
      border-radius: 8px;
      margin-bottom: 24px;
      
      &:last-child {
        margin-bottom: 0;
      }

      ${ItemInner} {
        flex-direction: column;
        padding-bottom: 0;
        border-bottom: 0;
      }
      ${Description} {
        margin: 16px 0 0;
        ul {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 450;
          line-height: 24px;
          color: #183028;
        }
      }
      ${FwdDocumentIcon} {
        border-radius: 8px;
        border: 1px solid #EAEAEA;
        background: #FAFAFA;
        padding: 8px;
        width: 40px;
        height: 40px;
        svg {
          width: 24px;
        }
      }
      ${ItemLink} {
        display: flex;
        padding: 8px 16px 8px 8px;
        align-items: center;
        gap: 8px;
        border: 1px solid #DBDFE1;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        &::before {
          width: 2.5px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: #E87722;
          content: " ";
          position: absolute;
        }
        ${FwdDocumentIcon} {
          border: none;
          background: #fff;
          margin-left: auto;
        }
        ${ItemDescription} {
          font-size: 14px;
          color: #859D99;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        ${ItemContent} {
          white-space: pre-wrap;
        }
        ${ItemTitle} {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        
      }
    }
  }
`;

const Container = styled.section<{ color: string }>`
  background-color: ${(props) => props.color || colorsUtils.fwdWhite};
  position: relative;
  padding: 0 1rem 1rem;
  &.horizontal-vertical {
    padding: 0 5px;
    ${InnerWrap} {
      padding-top: 0;
      padding-bottom: 0px;
    }
    ${ItemsWrapper} {
      margin-top: 0;
    }
  }
  
`;

const ShowAllLink = styled.a<{ bgColor: string; textColor: string }>`
  display: inline-flex;
  padding: 8px 10px;
  line-height: 1.25rem;
  border-radius: 5px;
  align-items: center;
  text-decoration: none;

  svg {
    width: 100%;
    height: 100%;
    path {
      fill: ${(props) =>
    props.bgColor === colorsUtils.fwdOrange.toUpperCase()
      ? props.textColor
      : colorsUtils.fwdOrange};
    }
  }

  &:hover {
    background-color: ${(props) =>
    props.bgColor === colorsUtils.fwdOrange.toUpperCase()
      ? colorsUtils.fwdWhite20
      : colorsUtils.orange};
  }
`;

const ShowAllLinkText = styled.span<{ textColor: string }>`
  font-weight: 500;
  color: ${(props) => props.textColor};
`;

export {
  Container,
  FwdDocumentIcon,
  FwdShowAllIcon,
  ItemInner,
  InnerWrap,
  Description,
  ItemWrapper,
  ItemLink,
  ItemTitle,
  ItemsWrapper,
  DocumentItemTitle,
  DocumentItemTop,
  ShowAllLink,
  ShowAllLinkText,
  ItemContent,
  FwdFileIcon,
  ItemDescription
}