import React, { useContext, useEffect, useState } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { BrandIcon } from "components/BrandIcon";
import {
  Container, Title,
  Description, BrandIconWrapper,
  ContentSectionV2Wrapper, ContentSectionV2Content, ContentTitle, ContentDescription,
  ContentSubDescription, ContentHighlightDescription, ContentHighlightText,
  Image
} from "./ContentSectionV2.style";
import { CSContentSectionV2Props, ContentSectionV2Data, ContentSectionV2Props } from "./ContentSectionV2.interface";
import I18nContext from "i18n/context/LanguageContext";
import DOMPurify from "isomorphic-dompurify";
import FwdDataTable from "shared-components/FwdDataTable";
import map from "lodash/map";
import countBy from "lodash/countBy";
import { combineClassName } from "utils/styled";

export const getDataRows = (caseTable: any) => {
  let rowObject = {};
  caseTable.forEach(({ title, description }, idx) => {
    const key = title?.replace(" ", "");
    rowObject[key] = description;
  });
  return [rowObject];
};

export const getDataColumns = (caseTable: any) => {
  const lengthCaseTable = caseTable.length;
  return map(caseTable, ({ title, description, footer_description }) => {
    return {
      field: title?.replace(" ", ""),
      headerName: title,
      width: 808 / lengthCaseTable,
      renderCell: (params: any) => {
        return <div dangerouslySetInnerHTML={{
          __html: DOMPurify?.sanitize(description, {
            ADD_ATTR: ["target"],
          }),
        }} />;
      }
    }
  });
};

export const getDataFooters = (caseTable: any) => {
  let rowObject = {};
  caseTable.forEach(({ title, footer_description }, idx) => {
    const key = title?.replace(" ", "");
    rowObject[key] = footer_description;
  });
  const totalDesc = countBy(caseTable, "footer_description");
  return totalDesc[""] === caseTable.length ? [] : [rowObject];
};

const mapDataContentSection = (
  data: ContentSectionV2Props,
) => {
  return map(data?.content, ({ title, description, desktop_background, mobile_background,
    sub_description, icon, highlight_description, case_table }, idx) => {
    const hasCaseTable = case_table && case_table.length > 0;
    return {
      title,
      description,
      desktop_background,
      mobile_background,
      sub_description,
      icon,
      highlight_description,
      case_table,
      dataRows: hasCaseTable ? getDataRows(case_table) : [],
      dataColumns: hasCaseTable ? getDataColumns(case_table) : [],
      dataFooters: hasCaseTable ? getDataFooters(case_table) : [],
    };
  });
};
const GTM_COMPONENT_NAME = "ContentSectionV2 Component";
export function transformCSContentSectionV2Value(
  refData: any
): ContentSectionV2Props {
  const { title, display_title, subtitle, isHorizontalVertical, content } = refData;
  return {
    title, display_title, subtitle, isHorizontalVertical, content
  };
}

export const CSContentSectionV2 = (csData: CSContentSectionV2Props) => {
  const refData = csData?.data;
  if (!refData) return <></>;

  const ContentSectionV2PropsData: ContentSectionV2Props = transformCSContentSectionV2Value(refData);
  return <ContentSectionV2 data={ContentSectionV2PropsData} />;
}

export const ContentSectionV2: React.FC<ContentSectionV2Data> = (props) => {
  const data = props.data as ContentSectionV2Props;
  const isMobile = useMediaQuery(`(max-width: 767px)`);
  const fwdDataTableClass = "fwd-data-table-2";
  const i18nContext = useContext(I18nContext);

  const [dataContentSection, setDataContentSection] = useState<any[]>([]);

  useEffect(() => {
    setDataContentSection(mapDataContentSection(data));
  }, [data]);

  return (
    <Container>
      <Box>
        {!props.data?.isHorizontalVertical && (<>
          <Title>{data?.display_title}</Title>
          <Description>{data?.subtitle}</Description>
        </>)}
        <ContentSectionV2Wrapper>
          {dataContentSection.length > 0 && dataContentSection.map(({ desktop_background, mobile_background, title, description, sub_description,
            icon, highlight_description, dataRows, dataColumns, dataFooters }, idx) => (
            <ContentSectionV2Content key={idx}>
              {desktop_background &&
                <Image alt={!isMobile ? desktop_background?.description : mobile_background?.description} src={!isMobile ? desktop_background?.url : mobile_background?.url} />
              }

              {icon?.url && <BrandIconWrapper>
                <BrandIcon icon={icon?.url} width={"auto"} height={"60px"} />
              </BrandIconWrapper>}
              {title && <ContentTitle>{title}</ContentTitle>}
              {description && <ContentDescription
                dangerouslySetInnerHTML={{
                  __html: DOMPurify?.sanitize(description, {
                    ADD_ATTR: ["target"],
                  }),
                }}
              />}
              {dataRows && dataRows.length > 0 &&
                (<FwdDataTable rows={dataRows} columns={dataColumns}
                  footers={dataFooters}
                  className={dataColumns && dataColumns.length > 2 ? fwdDataTableClass : combineClassName(fwdDataTableClass, "fwd-default")} />)}
              {sub_description && <ContentSubDescription
                dangerouslySetInnerHTML={{
                  __html: DOMPurify?.sanitize(sub_description, {
                    ADD_ATTR: ["target"],
                  }),
                }}
              />}

              {highlight_description?.description && <ContentHighlightDescription>
                {highlight_description?.icon?.url && <BrandIcon icon={highlight_description?.icon?.url} width={"auto"} height={"24px"} />}
                <ContentHighlightText dangerouslySetInnerHTML={{
                  __html: DOMPurify?.sanitize(highlight_description?.description, {
                    ADD_ATTR: ["target"],
                  }),
                }} />
              </ContentHighlightDescription>}
            </ContentSectionV2Content>
          ))}
        </ContentSectionV2Wrapper>
      </Box>
    </Container>
  );
}
