import React from "react";
import styled from "styled-components";
import map from "lodash/map";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LinearProgress from "@material-ui/core/LinearProgress";
import { colorsUtils, breakpoint } from "@d2c-ui-components-react";
import TableFooter from '@material-ui/core/TableFooter';

export interface IColumnProps {
  field: string;
  headerName: string;
  width?: number | string;
  renderCell?: (params: any) => JSX.Element;
}

export interface IDataTableProps {
  columns: IColumnProps[];
  rows: any[];
  isLoading?: boolean;
  footers?: any[];
  className?: string;
}

const DataTableContainer = styled.div`
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #dbdfe1;
  max-width: 100%;
  overflow-x: auto;
  &.fwd-data-table-2 {
    border-color: #F3BB90;
    max-width: calc(100% + 16px);
    width: calc(100% + 16px);
    ${breakpoint("md")`
      width: auto;
      max-width: 100%;
    `}

    &.fwd-default {
      max-width: 100%;
      width: auto;
    }
  }
`;


const CellInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  &.inner-bordered:before {
    content: "";
    position: absolute;
    left: 0;
    top: 16px;
    height: calc(100% - 32px);
    width: 1px;
    background: #dbdfe1;
  }
`;


const DataTable = styled(Table)`
  &.MuiTable-root {
    border-collapse: collapse;
    min-width: 600px;

    ${breakpoint("lg")`
      min-width: 1060px;
    `}

    .MuiTableHead-root {
      .MuiTableRow-head {
        background-color: ${colorsUtils.fwdOrange};
        border-color: ${colorsUtils.fwdOrange};
      }

      th {
        color: ${colorsUtils.fwdWhite};
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        position: relative;
        height: 56px;

        &:first-child {
          .inner-bordered:before {
            display: none;
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        td {
          padding: 8px 16px;
          border-left: 0;
          border-right: 0;
          position: relative;
          height: 56px;
          font-size: 16px;
          line-height: 24px;
          font-weight: 450;

          &:first-child {
            .inner-bordered:before {
              display: none;
            }
          }
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
    &.fwd-data-table-2 {
      min-width: auto;
      border-color: #F3BB90;
      .MuiTableBody-root .MuiTableRow-root td {
        vertical-align: top;
        border-color: #F3BB90;
        min-width: 180px;
      }
      .MuiTableHead-root .MuiTableRow-head {
        background-color: #FEF9F4;
        border-color: #F3BB90;
      }
      .MuiTableHead-root th {
        color: #E87722;
        border-color: #F3BB90;
      }
      .MuiTableRow-footer {
        background: #F8F9F9;
        border-top: 1px solid #DBDFE1;
        ${CellInner} {
          font-size: 16px;
          font-weight: 450;
          color: #183028;
        }
      }
      .MuiTableCell-body,
      .MuiTableCell-footer {
        position: relative;
      }
    }
  }
`;

const TableProgressBody = styled.tbody`
  position: relative;
  .MuiLinearProgress-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .MuiLinearProgress-barColorPrimary {
      background: #fff;
    }
  }
`;

const FwdDataTable = ({
  columns,
  rows,
  isLoading = false,
  className,
  footers
}: IDataTableProps) => {
  return (
    <DataTableContainer className={className}>
      <DataTable className={className}>
        <TableHead>
          <TableRow>
            {map(columns, (column: any, columnIndex: number) => (
              <TableCell key={`${column?.field}-${columnIndex}`} align="left">
                <CellInner className="inner-bordered">
                  {column?.headerName}
                </CellInner>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isLoading && (
          <TableProgressBody>
            <tr>
              <td>
                <LinearProgress />
              </td>
            </tr>
          </TableProgressBody>
        )}
        <TableBody>
          {rows.map((row: any, rowIndex: number) => (
            <TableRow key={`row-${row.name}-${rowIndex}`}>
              {map(columns, (column: any, columnIndex: number) => (
                <TableCell
                  key={`${column?.field}-${rowIndex}-${columnIndex}`}
                  component="td"
                  scope="row"
                  width={column?.width}
                >
                  <CellInner className="inner-bordered">
                    {column?.renderCell
                      ? column?.renderCell({ value: row[column?.field] })
                      : row[column?.field]}
                  </CellInner>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
        {footers && footers.length > 0 && (<TableFooter>
          {footers.map((row: any, rowIndex: number) => (
            <TableRow key={`row-${row.name}-${rowIndex}`}>
              {map(columns, (column: any, columnIndex: number) => (
                <TableCell
                  key={`${column?.field}-${rowIndex}-${columnIndex}`}
                  component="td"
                  scope="row"
                  width={column?.width}
                >
                  <CellInner className="inner-bordered">
                    {row[column?.field]}
                  </CellInner>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableFooter>)}

      </DataTable>
    </DataTableContainer>
  );
};

export default FwdDataTable;
