
import styled from "styled-components";
import { breakpoint } from "@d2c-ui-components-react";

const Container = styled.div`
  margin: 0 auto 32px;
  max-width: 1288px;
`;

const Title = styled.section`
  font-size: 31px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: #183028;
  line-height: 39px;
  margin-bottom: 16px;
  ${breakpoint("md")`
    font-size: 39px;
    line-height: 49px; 
    margin-bottom: 0px;
  `}
`;

const Description = styled.section`
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  text-align: center;
  color: #183028;
  margin-bottom: 32px;
`;
const Image = styled.img`
  width: 100%;
  margin-bottom: 24px;
  ${breakpoint("sm")`
  
`};
`;

const BrandIconWrapper = styled.div`
  width: 100px;
  height: 100px;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentSectionV2Wrapper = styled.div`
`;

const ContentSectionV2Content = styled.div`
  margin-top: 24px;
`;

const ContentTitle = styled.div`
  padding: 16px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
`;

const ContentDescription = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px; 
  color: #212529;
  margin-bottom: 16px;
`;

const ContentSubDescription = styled.div`
  font-size: 16px;
  font-weight: 450;
  line-height: 24px; 
  color: #666;
  margin-top: 16px;
`;

const ContentHighlightDescription = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  color: #E87722;
  background-color: #FEF9F4;
  border-radius: 8px;
  div[class^="BrandIcon__Wrapper"] {
    img {
      width: auto!important;
    }
  }
  
`;

const ContentHighlightText = styled.div`
  font-size: 16px;
  font-weight: 450;
  line-height: 24px; 
  color: #E87722;
  margin-left: 10px;
  p {
    margin-bottom: 0px;
  }
`;

export {
  Container,
  Title,
  Description,
  BrandIconWrapper,
  ContentSectionV2Wrapper,
  ContentSectionV2Content,
  ContentTitle,
  ContentDescription,
  ContentSubDescription,
  ContentHighlightDescription,
  ContentHighlightText,
  Image
}
