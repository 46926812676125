import { replaceContentStackURL } from "@utils";
import { BrandIcon } from "components/BrandIcon";
import MainLayout from "components/MainLayout";
import { Heading } from "components/Typography";
import I18nContext from "i18n/context/LanguageContext";
import DOMPurify from "isomorphic-dompurify";
import React, { useContext } from "react";
import {
  DownloadListData,
  DownloadListItemData,
  DownloadListProps,
} from "types/DownloadList.interface";
import gtmUtils, { gaEventAction } from "utils/gtm";
import { isImageURL } from "utils/iconUtils";
import { resolveAbsoluteUrl, resolveAbsoluteUrlInParagraph } from "utils/route";
import isEmpty from "lodash/isEmpty";
import { NamedIcon } from "@fwd-dep/nextgen-ui-lib";
import {
  Container,
  ItemWrapper, ItemInner, ItemLink, ItemTitle,
  FwdDocumentIcon, DocumentItemTop, DocumentItemTitle,
  FwdFileIcon, ItemContent, InnerWrap, ItemsWrapper,
  ShowAllLink, FwdShowAllIcon, ShowAllLinkText,
  ItemDescription, Description
} from "./DownloadList.style";
import groupBy from "lodash/groupBy";
import uniqBy from "lodash/uniqBy";
import { isFile } from "utils/string";
import { getDataRows, getDataColumns, getDataFooters } from "components/ContentSectionV2/ContentSectionV2.component";
import { combineClassName } from "utils/styled";
import FwdDataTable from "shared-components/FwdDataTable";
import { ContentHighlightDescription, ContentHighlightText } from "components/ContentSectionV2/ContentSectionV2.style";

const GTM_COMPONENT_NAME = "DownloadList";
const DownloadListItem: React.FC<{
  item: DownloadListItemData;
  textColor: string;
  bgColor: string;
}> = ({ item, textColor, bgColor }) => {
  const i18nContext = useContext(I18nContext);
  const onDownload = () => {
    gtmUtils.pushEvent({
      brochure: {
        name: item?.title,
        url: item?.file?.url,
      },
    });
    const gtmObj: { [key: string]: any } = {
      event: "buttonClick",
      buttonTitle: "download",
      componentName: GTM_COMPONENT_NAME,
      destinationUrl: replaceContentStackURL(item?.file?.url || item?.url),
    };

    gtmUtils.buttonClick(gtmObj);
    gtmUtils.navigation({
      event_action: gaEventAction.button_click,
      button_title: gtmObj.buttonTitle,
      destination_url: gtmObj.destinationUrl,
      component_name: GTM_COMPONENT_NAME,
      product_affiliation: i18nContext.componentConfigs?.gaPageData?.product_affiliation || ""
    });
  };

  return (
    <ItemWrapper>
      <ItemInner color={item?.color || ""}>
        <ItemLink
          href={replaceContentStackURL(item?.file?.url || item?.url)}
          target={item?.openInNewTab ? "_blank" : "_self"}
          onClick={onDownload}
        >
          <ItemTitle color={textColor}>{item?.title}</ItemTitle>
          <FwdDocumentIcon
            bgColor={bgColor?.toUpperCase()}
            textColor={textColor}
          >
            <BrandIcon
              icon={item?.iconName || "download"}
              width="26px"
              height="28px"
            />
          </FwdDocumentIcon>
        </ItemLink>
      </ItemInner>
    </ItemWrapper>
  );
};

const DownloadListDocument: React.FC<{
  item: DownloadListItemData;
  textColor: string;
  bgColor: string;
}> = ({ item, textColor, bgColor }) => {
  const hasCaseTable = item?.caseTable && item?.caseTable.length > 0;
  const dataRows =  hasCaseTable ? getDataRows(item?.caseTable) : [];
  const dataColumns = hasCaseTable ? getDataColumns(item?.caseTable) : [];
  const dataFooters = hasCaseTable ? getDataFooters(item?.caseTable) : [];
  const i18nContext = useContext(I18nContext);
  const fwdDataTableClass = "fwd-data-table-2";
  const onDownload = (e, file: any) => {
    gtmUtils.pushEvent({
      brochure: {
        name: file?.title,
        url: file?.url,
      },
    });
    const gtmObj: { [key: string]: any } = {
      buttonTitle: file?.gaEvent || "download",
      componentName: GTM_COMPONENT_NAME,
      destinationUrl: replaceContentStackURL(file?.url),
      gaEvent: file?.gaEvent
    };

    gtmUtils.buttonClick(gtmObj);
    gtmUtils.navigation({
      event_action: gaEventAction.button_click,
      button_title: gtmObj.buttonTitle,
      destination_url: gtmObj.destinationUrl,
      component_name: GTM_COMPONENT_NAME,
      product_affiliation: i18nContext.componentConfigs?.gaPageData?.product_affiliation || ""
    });
  };

  return (
    <ItemWrapper className="document">
      <ItemInner color={item?.color || ""}>
        <DocumentItemTop>
          <FwdDocumentIcon
            bgColor={bgColor?.toUpperCase()}
            textColor={textColor}
          >
            <BrandIcon
              icon={item?.iconName || "download"}
              width="26px"
              height="28px"
            />
          </FwdDocumentIcon>
          <DocumentItemTitle>{item?.title}</DocumentItemTitle>
        </DocumentItemTop>
        {item?.files && item?.files.map((file) => (
          <ItemLink
            href={replaceContentStackURL(file?.url || item?.url)}
            target={file?.openInNewTab ? "_blank" : "_self"}
            onClick={(e) => onDownload(e, file)}
          >
            <FwdFileIcon bgColor={bgColor?.toUpperCase()}
              textColor={textColor} className="pdf-icon">
              <NamedIcon icon={"pdf"} />
            </FwdFileIcon>
            <ItemContent>
              <ItemTitle color={textColor}>{file?.title}</ItemTitle>
              <ItemDescription color={textColor}>{file?.description}</ItemDescription>
            </ItemContent>
            <FwdDocumentIcon
              bgColor={bgColor?.toUpperCase()}
              textColor={textColor}
            >
              <BrandIcon
                icon={"download"}
                width="26px"
                height="28px"
              />
            </FwdDocumentIcon>
          </ItemLink>
        ))}
        {item?.description &&
          <Description>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  resolveAbsoluteUrlInParagraph(
                    item?.description,
                    i18nContext
                  ),
                  {
                    ADD_ATTR: ["target"],
                  }
                ),
              }}
            />
          </Description>
        }

        {dataRows && dataRows.length > 0 &&
          (<FwdDataTable rows={dataRows} columns={dataColumns}
            footers={dataFooters}
            className={dataColumns && dataColumns.length > 2 ? fwdDataTableClass : combineClassName(fwdDataTableClass, "fwd-default")} />)}
        
        {item.toolTipContent && item.toolTipContent.length > 0 && (
          item.toolTipContent?.map(tip => (
            <ContentHighlightDescription>
              {tip?.icon && <BrandIcon icon={tip?.icon?.url} width={"auto"} height={"24px"} />}
              <ContentHighlightText dangerouslySetInnerHTML={{
                __html: DOMPurify?.sanitize(tip?.description, {
                  ADD_ATTR: ["target"],
                }),
              }} />
            </ContentHighlightDescription>
          ))
        )}
      </ItemInner>
    </ItemWrapper>
  );
};

export const DownloadList: React.FC<DownloadListProps> = (props) => {
  const title = props.data?.title ?? "";
  const displayTitle = props.data?.display_title ?? "";
  const designSettings = props.data?.design_settings ?? "";
  let items = props.data?.items ?? [];
  let groupDataByFileAndTitles = groupBy((props.data?.items?.filter(item => item.isFile)), "title");
  const showAll = props.data?.showAll ?? {
    title: "",
    url: "",
    iconName: "",
    openInNewTab: false,
    gaLabel: "",
  };

  if (designSettings === "document") {
    items = uniqBy(items, "title");
    items.forEach(item => {
      if (!!groupDataByFileAndTitles[item.title]) {
        item.files = groupDataByFileAndTitles[item.title].map((f: any) => {
         
          return {
            title: f.file?.title,
            description: f.file?.description,
            url: f.file?.url,
            openInNewTab: f?.openInNewTab,
            gaEvent: f?.gaEvent,
            toolTipContent: f?.toolTipContent
          };
        });
      }
    })
  }

  return (
    <Container
      className={props.className}
      color={props.data?.backgroundColor || ""}
    >
      <MainLayout maxWidth="1288px">
        <InnerWrap useParentPadding={props.data?.useParentPadding}>
          {!props.data?.isHorizontalVertical &&
            <Heading
              component={"h2"}
              variant={"h5"}
              style={{ color: props.data?.titleColor || "" }}
            >
              {displayTitle || title}
            </Heading>
          }
          {!props.data?.isHorizontalVertical && props.data.description && (
            <Description>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    resolveAbsoluteUrlInParagraph(
                      props.data.description,
                      props.data.i18nContext
                    ),
                    {
                      ADD_ATTR: ["target"],
                    }
                  ),
                }}
              />
            </Description>
          )}
          <ItemsWrapper itemsPerLine={props.data.itemsPerLine} designSettings={designSettings} className={designSettings === "document" ? "main-doc" : ""}>
            {designSettings === "document" && items.map((item) => (
              <DownloadListDocument
                key={item?.id}
                item={item}
                textColor={props.data?.titleColor || ""}
                bgColor={props.data?.backgroundColor || ""}
              />
            ))}
            {designSettings !== "document" && items.map((item) => (
              <DownloadListItem
                key={item?.id}
                item={item}
                textColor={props.data?.titleColor || ""}
                bgColor={props.data?.backgroundColor || ""}
              />
            ))}
          </ItemsWrapper>
          {showAll?.title && showAll?.title.length > 0 ? (
            <ShowAllLink
              href={showAll?.url}
              bgColor={props.data?.backgroundColor || ""}
              textColor={props.data?.titleColor || ""}
              target={showAll?.openInNewTab ? "_blank" : "_self"}
            >
              <FwdShowAllIcon
                bgColor={props.data?.backgroundColor || ""}
                textColor={props.data?.titleColor || ""}
              >
                <BrandIcon icon={showAll?.iconName || "arrow-right"} />
              </FwdShowAllIcon>
              <ShowAllLinkText textColor={props.data?.titleColor || ""}>
                {showAll?.title}
              </ShowAllLinkText>
            </ShowAllLink>
          ) : (
            ""
          )}
        </InnerWrap>
      </MainLayout>
    </Container>
  );
};

export const CSDownloadList = (csData: any) => {
  const { reference } = csData?.data;
  const i18nContext = useContext(I18nContext);

  const downloadListCSData = reference?.[0] || csData?.data || {};
  if (isEmpty(downloadListCSData)) {
    return <div>Missing Download List data</div>;
  }

  const {
    title,
    display_title,
    title_color,
    description,
    design_settings,
    cta,
    items,
    background_color,
    items_per_line = 5,
  } = downloadListCSData;

  const {
    title: cta_title,
    url: cta_url,
    icon: cta_icon_name,
    open_in_new_tab,
    ga_label = "",
  } = cta || {};



  const data: DownloadListData = {
    i18nContext,
    title,
    display_title,
    titleColor: title_color,
    description,
    design_settings,
    itemsPerLine: items_per_line,
    backgroundColor: background_color,
    showAll: {
      title: cta_title,
      url: resolveAbsoluteUrl(cta_url, i18nContext),
      iconName: cta_icon_name,
      openInNewTab: open_in_new_tab,
      gaLabel: ga_label,
    },
    items: (items || []).map(
      ({ title, description, file, icon, url,
        border_bottom_color, open_in_new_tab, ga_label,
        file_title, file_description, case_table, tooltip_content }) => ({
          id: file?.url || url,
          title,
          description,
          file: {
            url: file?.url || (isFile(file?.url || url) ? url : ""),
            title: (isFile(file?.url || url) ? file_title : file?.title) || "",
            description: (isFile(file?.url || url) ? file_description : file?.description) || "",
          },
          isFile: !!file?.url || isFile(url),
          files: [],
          url: resolveAbsoluteUrl(url, i18nContext),
          iconName: icon,
          isImageIcon: isImageURL(icon),
          color: border_bottom_color,
          openInNewTab: open_in_new_tab,
          gaEvent: ga_label,
          caseTable: case_table,
          toolTipContent: tooltip_content
        })
    ),
    useParentPadding: csData?.data?.useParentPadding,
    isHorizontalVertical: csData?.data?.isHorizontalVertical
  };
  return <DownloadList data={data} className={data.isHorizontalVertical ? "horizontal-vertical" : ""} />;
};
